import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact ,IonSpinner} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Login from './pages/Login';

// Login Auth
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
// import { getToken } from "./utils/Common";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import lg from './assets/phi.png'
/* Theme variables */
import './theme/variables.css';
setupIonicReact();


const Dashboard = React.lazy(() => import('./pages/Dashboard'));

// Approval
const Eapp = React.lazy(() => import('./pages/EApp'));
const PP = React.lazy(() => import('./pages/EApp/PurchaseProposal'));
const PR = React.lazy(() => import('./pages/EApp/PurchaseRequest'));
const PRPM = React.lazy(() => import('./pages/EApp/PRforPM'));
const PRCA = React.lazy(() => import('./pages/EApp/PRforCA'));

const PO = React.lazy(() => import('./pages/EApp/PurchaseOrder'));
const RI = React.lazy(() => import('./pages/EApp/ReceiveItem'));


const ListPurchaseProposal = React.lazy(() => import('./pages/EApp/PurchaseProposal/List'));
const ListPurchaseRequest = React.lazy(() => import('./pages/EApp/PurchaseRequest/List')); 
const ListPurchaseRequestPM = React.lazy(() => import('./pages/EApp/PRforPM/List'));
const ListPurchaseRequestCA = React.lazy(() => import('./pages/EApp/PRforCA/List'));
const ListPurchaseOrder = React.lazy(() => import('./pages/EApp/PurchaseOrder/List'));
const ListReceiveItem = React.lazy(() => import('./pages/EApp/ReceiveItem/List')); 

const DetailPurchaseOrder = React.lazy(() => import('./pages/EApp/PurchaseOrder/Detail'));
const DetailReceiveItem = React.lazy(() => import('./pages/EApp/ReceiveItem/Detail'));
const DetailPurchaseProposal = React.lazy(() => import('./pages/EApp/PurchaseProposal/Detail'));
const DetailPurchaseRequest = React.lazy(() => import('./pages/EApp/PurchaseRequest/Detail'));
const DetailPurchaseRequestPM = React.lazy(() => import('./pages/EApp/PRforPM/Detail'));
const DetailPurchaseRequestCA = React.lazy(() => import('./pages/EApp/PRforCA/Detail'));

const Email = React.lazy(() => import('./pages/Email'));
// Taskman
const Etask = React.lazy(() => import('./pages/Etask'));
const ListTaskman = React.lazy(() => import('./pages/Etask/List'));
const DetailTaskman = React.lazy(() => import('./pages/Etask/Detail'));

//Keydeposite
const KeyDeposite = React.lazy(() => import('./pages/KeyDeposite'));
const ListKeyDeposite = React.lazy(() => import('./pages/KeyDeposite/List'));
const DetailKeyDeposite = React.lazy(() => import('./pages/KeyDeposite/Detail.jsx'));

//Matrix
const Matrix = React.lazy(() => import('./pages/Matrix'));
const ListMatrix = React.lazy(() => import('./pages/Matrix/List'));

// FnB
const Resto = React.lazy(() => import('./pages/FnB'));
const ReportFnB = React.lazy(() => import('./pages/FnB/Report'));

// HKMobile
const HKMobile = React.lazy(() => import('./pages/HKMobile'));
const LisRoomHK = React.lazy(() => import('./pages/HKMobile/List'));

const App: React.FC = () => (
  
  <IonApp>
  
    <IonReactRouter>
      <IonRouterOutlet>
        <PublicRoute exact path="/" component={Login}/>
        <Suspense fallback={
          <div style={{textAlign:"center",marginTop:"35%"}}>
            <img src={lg} style={{width:"100px"}} />
          </div>
        }>

            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/e-approval" component={Eapp} />
            <PrivateRoute exact path="/purchase-proposal" component={PP} />
            <PrivateRoute exact path="/purchase-request" component={PR} />
            <PrivateRoute exact path="/purchase-request/pm" component={PRPM} />
            <PrivateRoute exact path="/purchase-request/ca" component={PRCA} />
            <PrivateRoute exact path="/purchase-order" component={PO} />
            <PrivateRoute exact path="/receive-item" component={RI} />
            <PrivateRoute exact path="/list-purchase-proposal/:IDHOTEL" component={ListPurchaseProposal} />
            <PrivateRoute exact path="/list-purchase-request/:IDHOTEL" component={ListPurchaseRequest} />
            <PrivateRoute exact path="/list-purchase-request/pm/:IDHOTEL" component={ListPurchaseRequestPM} />
            <PrivateRoute exact path="/list-purchase-request/ca/:IDHOTEL" component={ListPurchaseRequestCA} />
            <PrivateRoute exact path="/list-purchase-order/:IDHOTEL" component={ListPurchaseOrder} />
            <PrivateRoute exact path="/list-receive-item/:IDHOTEL" component={ListReceiveItem} />
            <PrivateRoute exact path="/detail-receive-item/:IDPO" component={DetailReceiveItem} />
            <PrivateRoute exact path="/detail-purchase-proposal/:IDPP" component={DetailPurchaseProposal} />
            <PrivateRoute exact path="/detail-purchase-request/:IDPP" component={DetailPurchaseRequest} />
            <PrivateRoute exact path="/detail-purchase-request/pm/:IDPP" component={DetailPurchaseRequestPM} />
            <PrivateRoute exact path="/detail-purchase-request/ca/:IDPP" component={DetailPurchaseRequestCA} />
            <PrivateRoute exact path="/detail-purchase-order/:IDPO" component={DetailPurchaseOrder} />

            <PrivateRoute exact path="/email" component={Email} />
            
            <PrivateRoute exact path="/key/deposite" component={KeyDeposite} />
            <PrivateRoute exact path="/list/key/deposite/:IDHOTEL" component={ListKeyDeposite} />
            <PrivateRoute exact path="/detail/key-deposite/:IDKEY" component={DetailKeyDeposite} />

            <PrivateRoute exact path="/taskman" component={Etask} />
            <PrivateRoute exact path="/list/taskman/:IDHOTEL" component={ListTaskman} />
            <PrivateRoute exact path="/detail/taskman/:IDINSP" component={DetailTaskman} />

            <PrivateRoute exact path="/matrix" component={Matrix} />
            <PrivateRoute exact path="/list/occupancy/:IDHOTEL" component={ListMatrix} />
            
            <PrivateRoute exact path="/fnb" component={Resto} />
            <PrivateRoute exact path="/fnb/report/:IDHOTEL" component={ReportFnB} />

            <PrivateRoute exact path="/hk" component={HKMobile} />
            <PrivateRoute exact path="/list/room/hk/:IDHOTEL" component={LisRoomHK} />

        </Suspense>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
