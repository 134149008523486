import React, { useState,useEffect } from 'react';
import { IonContent,IonPage,IonAlert,IonLoading,IonFooter,IonToolbar,IonTitle} from '@ionic/react';
import {useHistory } from "react-router-dom";
import axios from '../../utils/axios';
import logo from '../../assets/phi.png'
import { setUserSession } from "../../utils/Common.js";

export default function  Login() {
    const history = useHistory();
    const headers = {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' };
    // var Here...
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [passwordType, setPasswordType] = useState("password");

    // Handle Show/Hide password

    const togglePass = () => {
        if(passwordType==="password")
        {
        setPasswordType("text")
        return;
        }
        setPasswordType("password")
    }

    //Handle Login
    const doLogin = () => {
        
        if(email === "" || password === "" || email === undefined || password === undefined){
         
            setAlertMessage('Fill in the form correctly');
            setShowAlert(true);
        }

        if(email === "" || email === undefined){
            setAlertMessage('Email cannot be empty');
            setShowAlert(true);
        }

        if(password === "" || password === undefined){
            setAlertMessage('Password cannot be empty');
            setShowAlert(true);
        }

        if(email && password ){
            setShowLoading(true);
            axios.post("/api/login", {email,password},{headers:headers})
            .then(function (response) {
               
                if(response.data.status === 200){
                    setShowLoading(false)
                    setUserSession(response.data.token, response.data.user);
                    history.push("/dashboard");
                }
                
            })
            .catch(function (error) {
                if (error) {
                    setAlertMessage('Users Not Found');
                    setShowAlert(true);
                }
            });


        }
    }
    useEffect((e) => {
        
        
    }, []);

    return (
        <IonPage id="main">

            {/* Ion Loading  */}
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={500}
                mode="ios"
            />
            {/* End Ion Loading  */}
            {/* Alert Notif */}
            <IonAlert
                mode="ios"
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={''}
                message={alertMessage}
                buttons={['OK']}
            />
            {/* End Alert Notif */}

            <IonContent scrollEvents={false} scroll-y="false" scroll-x="false">
                <div id="appCapsule" className="pt-0">
                    <div className="login-form mt-1">
                        <div className="section">
                            <img src={logo} alt="Prima Hotel Indonesia" className="form-image" />
                        </div>
                        
                        
                        <div className="section mt-1 mb-5">
                        
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <input type="email" className="form-control" id="email" placeholder="Email Address" onChange={(e)=>setEmail(e.target.value)}/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <input type={passwordType} className="form-control" id="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-links mt-2">
                                    <div>
                                        <a onClick={()=>togglePass()}>Show Password</a>
                                    </div>
                                    <div><a  className="text-muted">Forgot Password?</a></div>
                                </div>

                                <div className="form-button-group" style={{bottom:"45px"}}>
                                    <button className="btn btn-info btn-block btn-lg" onClick={()=>doLogin()}>Log in</button>
                                </div>
                        </div>
                    </div>
                </div>
            </IonContent>

            <IonFooter>
                <IonToolbar className='ion-text-center'>
                    <IonTitle style={{fontSize:"12px",color:"#3f7cf4"}}>Copyright @ Ashley Hotel Group </IonTitle>
                </IonToolbar>
            </IonFooter> 
        </IonPage>
    );
}


